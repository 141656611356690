<template>
  <v-app>
    <v-main class="login-background">
      <div class="d-flex align-center flex-column justify-center px-1 login-background-2">
        <v-card width="500px">
          <div class="card-header">
            <div class="d-flex align-center flex-column">
              <v-subheader class="display-1 mt-3">
                <v-icon large color="#2BA5B6">mdi-lock-open-outline</v-icon>Login
              </v-subheader>
              <v-card-title>Please enter your credentials to login.</v-card-title>
            </div>
          </div>
          <v-divider></v-divider>
          <v-card-text>
            <div class="logo-wrapper d-flex flex-column align-center pb-3">
              <v-subheader class="headline font-weight-bold">ASL Shipping Line</v-subheader>
              <v-img src="http://backend.aslshipping.com/images/logo.jpg" width="150px"></v-img>
            </div>
            <v-divider></v-divider>
            <v-form ref="loginForm" v-model="valid" lazy-validation >
              <v-row no-gutters class="px-3">
                <v-col cols="12">
                  <v-subheader class="subtitle-2 px-0">Username or Email</v-subheader>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    dense
                    outlined
                    prepend-inner-icon="mdi-email-outline"
                    hide-details
                    v-model="loginEmail"
                    :rules="loginEmailRules"
                    @keypress.enter="validate"
                    required
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-subheader class="subtitle-2 px-0">Password</v-subheader>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    dense
                    outlined
                    prepend-inner-icon="mdi-key-variant"
                    hide-details
                    v-model="loginPassword"
                    :append-icon="show1 ? 'mdi-eye-outline' : 'mdi-eye-off-outline'"
                    :rules="[rules.required, rules.min]"
                    :type="show1 ? 'text' : 'password'"
                    name="input-10-1"
                    hint="At least 2 characters"
                    counter
                    @click:append="show1 = !show1"
                    @keypress.enter="validate"
                  >
                  </v-text-field>
                </v-col>
                <v-col class="12">
                  <v-checkbox
                    label="Keep me signed in"
                    hide-details
                  ></v-checkbox>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions class="d-flex justify-end px-7 pb-5">
            <v-btn
              color="primary"
              class="px-5"
              :loading="loader"
              @click="validate"
            >Login</v-btn>
            <!-- <input type="text"  v-on:keyup.enter="onEnter()"> -->
          </v-card-actions>
        </v-card>
        <div class="bottom-text d-flex align-center flex-column mt-5">
          <p class="mb-0">Don't have an account? <span class="color-secondary font-weight-bold">Contact to ASL</span></p>
<!--          <p>Forgot <span class="color-secondary font-weight-bold">Password</span></p>-->
        </div>
        <h4>Copyright &copy; <span class="color-secondary">ASL Shipping Line</span></h4>
        
      </div>
      
    </v-main>
  </v-app>
</template>

<script>
import { mapActions } from "vuex";
import Api from "@/services/Api";
import localforage from "localforage";



export default {
  components: {  },
  name: "Login",
  data: () => ({
    dialog: true,
    tab: 0,
    tabs: [
      { name: "Login", icon: "mdi-account" },
      // {name:"Register", icon:"mdi-account-outline"}
    ],
    valid: true,
    loader: false,
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    verify: "",
    loginPassword: "",
    loginEmail: "",
    loginEmailRules: [
      (v) => !!v || "Required",
    ],
    emailRules: [
      (v) => !!v || "Required",
    ],

    show1: false,
    rules: {
      required: (value) => !!value || "Required.",
      min: (v) => (v && v.length >= 2) || "Min 6 characters",
    },
  }),
  computed: {
    passwordMatch() {
      return () => this.password === this.verify || "Password must match";
    },
  },
  methods: {
    ...mapActions({
      login: "auth/login",
      logout: "auth/logout",
    }),
    
    validate() {
      if (this.$refs.loginForm.validate()) {
        this.loader = true
        this.login({
          payload: { email: this.loginEmail, password: this.loginPassword },
          context: this,
        })
          .then((res) => {
            if (res.token) {
              localforage.getItem("intended", (err, value) => {
                if (value && value !== "/login") {
                  this.$router.replace(value);
                  localforage.removeItem("intended");
                  return;
                }
              });
              this.loader = false
              this.$router.push("/dashboard");
              return;
            }

            localforage.removeItem("authtoken").then(() => {
              this.$toastr.w("Unauthorized Access!");
            });
            this.$router.push("/login");
          })
          .catch((err) => {
            this.loading = false;
            this.loader = false
            if (err === "LOGIN_FAILED") {
              this.$toastr.e("Invalid Username or password!");
              return;
            }
            this.$toastr.e("Login Failed! " + err);
          });
      }
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
  },
};
</script>

<style scoped>
.login-background {
  height: 90%;
  width: 100vw;
  background-color: #EAEAEA;
}

.login-background-2 {
  height: 100%;
  width: 100vw;
}



.color-secondary {
  color: #2BA5B6
}

</style>